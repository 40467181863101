<template>
  <v-container class="container_movil">
    <v-list-item v-for="(item, i) in items" :key="i" class="list-group">
      <v-list-item-content class="list-group">
        <v-row>
          <v-col class="botonLineaMovilDatosSeparacion" cols="10">
            <v-btn
              class="botonRedondeado"
              color="primary"
              v-show="item.T1.seleccionado"
            >
              {{ item.T1.descripcion.slice(-4) }}</v-btn
            >
            <v-btn
              class="botonRedondeado"
              @click="marcarTarifaT1(i)"
              v-show="!item.T1.seleccionado"
            >
              {{ item.T1.descripcion.slice(-4) }}</v-btn
            >

            <v-btn
              class="botonRedondeado"
              color="primary"
              v-show="item.T2.seleccionado"
            >
              {{ item.T2.descripcion.slice(-4) }}
            </v-btn>
            <v-btn
              class="botonRedondeado"
              @click="marcarTarifaT2(i)"
              v-show="!item.T2.seleccionado"
            >
              {{ item.T2.descripcion.slice(-4) }}
            </v-btn>

            <v-btn
              class="botonRedondeado"
              color="primary"
              v-show="item.T3.seleccionado"
            >
              {{ item.T3.descripcion.slice(-4) }}
            </v-btn>
            <v-btn
              class="botonRedondeado"
              @click="marcarTarifaT3(i)"
              v-show="!item.T3.seleccionado"
            >
              {{ item.T3.descripcion.slice(-4) }}
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn
              fab
              small
              color="red"
              @click="quitarLineas(item)"
              class="botonMenosLineasAdicionales"
            >
              -
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-chip
              class="botonChip"
              color="primary"
              v-show="tarifasMarcadas[i].tipo_alta_movil == 'nueva linea'"
            >
              Alta
            </v-chip>
            <v-chip
              class="botonChip"
              v-show="tarifasMarcadas[i].tipo_alta_movil != 'nueva linea'"
              @click="pasarAlineaNueva(i)"
            >
              Alta
            </v-chip>
            <v-chip
              class="botonChip"
              v-show="tarifasMarcadas[i].tipo_alta_movil == 'portabilidad'"
              color="primary"
            >
              {{ $t("lineaMovil.portabilidad") }}
            </v-chip>
            <v-chip
              class="botonChip"
              v-show="tarifasMarcadas[i].tipo_alta_movil != 'portabilidad'"
              @click="pasarAPortabilidad(i)"
            >
              {{ $t("lineaMovil.portabilidad") }}
            </v-chip>
          </v-col>

          <v-col>
            <v-text-field
              class="textoLineaMovilDatosPorta"
              v-show="tarifasMarcadas[i].tipo_alta_movil == 'portabilidad'"
              :label="$t('lineaMovil.numeroParaPortar')"
              :rules="rulesMovil"
              @input="compruebaValidez"
              v-model="tarifasMarcadas[i].numeroActualTitular"
              background-color="#f1f1f1"
              rounded="True"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>

    <v-row>
      <v-col cols="9">
        <p class="tituloMasLineasAdicionales">
          {{ $t("lineaMovil.lineasAdicionales") }}
        </p>
      </v-col>
      <v-col cols="2">
        <v-btn
          fab
          small
          class="botonMasLineasAdicionales"
          color="primary"
          @click="aumentoLineas()"
        >
          +
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { uuid } from 'vue-uuid'

export default {
    data: () => ({
        items: [],
        tarifasMarcadas: '',
        rulesMovil: [
            (v) =>
                /^\+?[8-9]{1}-?[1-9]{1}-?[0-9]{7}$/.test(v) ||
        /^\+?[6-7]{1}-?[0-9]{8}$/.test(v) ||
        'Inserta un número válido'
        ]
    }),
    computed: {
        VLineasMovil () {
            return this.$store.getters.getswitchLineasMovil
        },
        Vcarrito () {
            return this.$store.getters.getcarritoPedidosLineasMovil
        }

    },
    watch: {
        Vcarrito (value) {
            this.tarifasMarcadas = []
            if (this.$store.getters.getswitchFijo) {
                for (let index = 2; index < value.length; index++) {
                    const element = value[index]
                    this.tarifasMarcadas.push(element)
                }
            } else {
                for (let index = 1; index < value.length; index++) {
                    const element = value[index]
                    this.tarifasMarcadas.push(element)
                }
            }
            this.compruebaValidez()

            if (this.$store.getters.getcarritoPedidosLineasMovil) {
                this.crearJsonPedidos(this.tarifasMarcadas)
            }
        },
        VLineasMovil (value) {
            if (value === false) {
                this.$store.dispatch('setdatosPortabilidadesValidados', true)
                this.$store.dispatch('setLineasMovil', [])
                this.tarifasMarcadas = []

                this.items = []
            } else {
                this.aumentoLineas()
            }
            if (value || this.$store.getters.getswitchFijo) {
                this.lineasPortabilidad = true
            } else {
                this.lineasPortabilidad = false
            }
        }
    },
    methods: {
        aumentoLineas () {
            // Para añadir nuevas tarifas debemos añadirlas en el array items y crear un método para controlarla. Como también reescribir el resto de marcarTarifas para
            // cambiar el seleccionado del estado
            // T4: { text4: '100 GB', seleccionado: false ,precio: 100, color: 'green' }
            if (this.items.length <= 4) {
                this.items.push({
                    T1: {
                        descripcion: 'Tarifa Móvil Ilimitada + 20Gb',
                        seleccionado: false,
                        tipo_alta_movil: 'nueva linea',
                        codigo_articulo: 'TMIS20GB',
                        precio: 11.75,
                        importe: 11.75,
                        tarifa: 'TMIS20GB',
                        tipo: 'movil'
                    },
                    T2: {
                        descripcion: 'Tarifa Móvil Ilimitada + 40Gb',
                        seleccionado: false,
                        tipo_alta_movil: 'nueva linea',
                        codigo_articulo: 'TMIC40GB',
                        precio: 15.75,
                        importe: 15.75,
                        tarifa: 'TMIC40GB',
                        tipo: 'movil'
                    },
                    T3: {
                        descripcion: 'Tarifa Móvil Ilimitada + 60Gb',
                        seleccionado: false,
                        tipo_alta_movil: 'nueva linea',
                        codigo_articulo: 'TMIR60GB',
                        precio: 19.75,
                        importe: 19.75,
                        tarifa: 'TMIR60GB',
                        tipo: 'movil'
                    }
                })
                const posicion = this.items.length
                this.marcarTarifaT1(posicion - 1)
                this.pasarAlineaNueva(posicion - 1)
            }
        },
        quitarLineas (item) {
            const itemDelete = this.items.indexOf(item)
            this.items.splice(itemDelete, 1)

            let lineas = []
            lineas = this.$store.getters.getLineasMovil
            lineas.splice(itemDelete, 1)
            this.$store.commit('SET_LINEASMOVIL', lineas)
            this.tarifasMarcadas.push(lineas)
        },

        marcarTarifaT1 (i) {
            this.items[i].T1.seleccionado = true
            this.items[i].T2.seleccionado = false
            this.items[i].T3.seleccionado = false
            this.items[i].T1.numeroActualTitular = ''
            this.items[i].T2.numeroActualTitular = ''
            this.items[i].T3.numeroActualTitular = ''
            this.items[i].T1.tipo_alta_movil = 'linea nueva'
            this.items[i].T2.tipo_alta_movil = 'linea nueva'
            this.items[i].T3.tipo_alta_movil = 'linea nueva'
            let lineas = []

            if (this.$store.getters.getLineasMovil.length > 0) {
                lineas = this.$store.getters.getLineasMovil

                if (lineas[i]) {
                    lineas.splice(i, 1, this.items[i].T1)
                } else {
                    lineas.push(this.items[i].T1)
                }
            } else {
                lineas.push(this.items[i].T1)
            }
            this.$store.dispatch('setLineasMovil', [])
            this.$store.dispatch('setLineasMovil', lineas)
            this.tarifasMarcadas = lineas
        },
        marcarTarifaT2 (i) {
            this.items[i].T1.seleccionado = false
            this.items[i].T2.seleccionado = true
            this.items[i].T3.seleccionado = false
            this.items[i].T1.numeroActualTitular = ''
            this.items[i].T2.numeroActualTitular = ''
            this.items[i].T3.numeroActualTitular = ''
            this.items[i].T1.tipo_alta_movil = 'linea nueva'
            this.items[i].T2.tipo_alta_movil = 'linea nueva'
            this.items[i].T3.tipo_alta_movil = 'linea nueva'
            let lineas = []
            if (this.$store.getters.getLineasMovil.length > 0) {
                lineas = this.$store.getters.getLineasMovil
                if (lineas[i]) {
                    lineas.splice(i, 1, this.items[i].T2)
                } else {
                    lineas.push(this.items[i].T2)
                }
            } else {
                lineas.push(this.items[i].T2)
            }
            this.$store.dispatch('setLineasMovil', [])
            this.$store.dispatch('setLineasMovil', lineas)
            this.tarifasMarcadas.push(lineas)
        },
        marcarTarifaT3 (i) {
            this.items[i].T1.seleccionado = false
            this.items[i].T2.seleccionado = false
            this.items[i].T3.seleccionado = true
            this.items[i].T1.numeroActualTitular = ''
            this.items[i].T2.numeroActualTitular = ''
            this.items[i].T3.numeroActualTitular = ''
            this.items[i].T1.tipo_alta_movil = 'linea nueva'
            this.items[i].T2.tipo_alta_movil = 'linea nueva'
            this.items[i].T3.tipo_alta_movil = 'linea nueva'

            let lineas = []
            if (this.$store.getters.getLineasMovil.length > 0) {
                lineas = this.$store.getters.getLineasMovil
                if (lineas[i]) {
                    lineas.splice(i, 1, this.items[i].T3)
                } else {
                    lineas.push(this.items[i].T3)
                }
            } else {
                lineas.push(this.items[i].T3)
            }
            this.$store.dispatch('setLineasMovil', [])
            this.$store.dispatch('setLineasMovil', lineas)
            this.tarifasMarcadas.push(lineas)
        },
        pasarAPortabilidad (i) {
            this.tarifasMarcadas[i].numeroActualTitular = ''
            this.tarifasMarcadas[i].identificadorNumeroTitular = []
            this.tarifasMarcadas[i].tipo_alta_movil = 'portabilidad'
            this.tarifasMarcadas[i].nombreTitular = ''
            this.tarifasMarcadas[i].apellidosTitular = ''
            this.tarifasMarcadas[i].identificadorNumeroTitular = []

            this.compruebaValidez()
        },
        pasarAlineaNueva (i) {
            this.tarifasMarcadas[i].tipo_alta_movil = 'nueva linea'
            this.tarifasMarcadas[i].numeroActualTitular = ''

            this.compruebaValidez()
        },
        compruebaValidez () {
            const patternMovil1 = /^\+?[8-9]{1}-?[1-9]{1}-?[0-9]{7}$/
            const patternMovil2 = /^\+?[6-7]{1}-?[0-9]{8}$/
            let validadorParametros = ''

            this.tarifasMarcadas.forEach((element) => {
                if (element.tipo_alta_movil === 'portabilidad') {
                    if (
                        patternMovil1.test(element.numeroActualTitular) ||
            patternMovil2.test(element.numeroActualTitular)
                    ) {
                    } else {
                        this.$store.dispatch('setdatosPortabilidadesValidados', false)
                        validadorParametros = false
                    }
                }
            })
            if (validadorParametros === '' && this.tarifasMarcadas.length > 0) {
                this.$store.dispatch('setdatosPortabilidadesValidados', true)
                this.crearJsonPedidos(this.tarifasMarcadas)
            }
        },
        addZero (i) {
            if (i < 10) {
                i = '0' + i
            }
            return i
        },

        crearJsonPedidos (valores) {
            var lineaFibra = this.$store.getters.getFibra
            var date_format = new Date()
            const mes = date_format.getMonth() + 1
            const h = this.addZero(date_format.getUTCHours())
            const m = this.addZero(date_format.getMinutes())
            const s = this.addZero(date_format.getSeconds())
            const time = h + ':' + m + ':' + s
            var fechaFormateada =
        mes +
        '/' +
        date_format.getDate() +
        '/' +
        date_format.getFullYear() +
        ' ' +
        time

            var lineasJ = []
            lineaFibra.id_linea = uuid.v1()
            lineasJ.push(lineaFibra)
            var lineaFijo = this.$store.getters.getswitchFijo

            if (lineaFijo) {
                lineaFijo.id_linea = uuid.v1()
                lineasJ.push(lineaFijo)
            }
            valores.forEach((element) => {
                if (element.tipo_alta_movil === 'portabilidad') {
                    const linea = {
                        id_linea: uuid.v1(),
                        tipo: element.tipo,
                        numero: element.numeroActualTitular, // si nueva linea vacio y si es portabilidad lo tenemos que pedir
                        nombreTitular: element.nombreTitular,
                        apellidosTitular: element.apellidosTitular,
                        identificadorNumeroTitular: 'no tiene DNI asociado',
                        tipo_alta_movil: element.tipo_alta_movil,
                        tarifa: element.tarifa,
                        codigo_articulo: element.codigo_articulo,
                        descripcion: element.descripcion,
                        unidades: '1',
                        precio: element.precio,
                        importe: element.importe
                    }
                    lineasJ.push(linea)
                } else {
                    const linea = {
                        id_linea: uuid.v1(),
                        tipo: element.tipo,
                        numero: '', // linea nueva va vacio
                        tipo_alta_movil: element.tipo_alta_movil,
                        tarifa: element.tarifa,
                        codigo_articulo: element.codigo_articulo,
                        descripcion: element.descripcion,
                        unidades: '1',
                        precio: element.precio,
                        importe: element.importe
                    }
                    lineasJ.push(linea)
                }
            })

            const pedidoOrder = {
                lineas: lineasJ
            }
            var order_infoJ = this.$store.getters.getjsonPedido

            if (order_infoJ) {
                order_infoJ.pedidoOrder.lineas = lineasJ
                this.$store.dispatch('setjsonPedido', order_infoJ)
            } else {
                const order_infoJ = {
                    idDistribuidor: 0,
                    pedidoOrder: pedidoOrder,
                    estadoOrder: 'pendiente',
                    clienteCreado: 'false',
                    origen: 'web',
                    fecha: fechaFormateada,
                    id_pedido: uuid.v1()
                }
                this.$store.dispatch('setjsonPedido', order_infoJ)
            }
        }
    },
    created () {
        this.item = []
        this.tarifasMarcadas = ''
    }
}
</script>
